// app/javascript/controllers/chart_controller.js

import { Controller } from "@hotwired/stimulus";
import Chart from "chart.js/auto";
import 'chartjs-adapter-moment';

export default class extends Controller {
  async connect() { // use async because fetch returns a Promise
    const ctx = this.element.querySelector("canvas").getContext("2d");
    const objective = JSON.parse(this.data.get("objectiveValue"));

    // fetch the new line data
    const response = await fetch(`/objectives/${objective.id}/chart_data`);
    const lineData = await response.json();
    console.log(lineData);
    // format the line data for Chart.js
    const formattedLineData = lineData.map(point => ({x: new Date(point.x), y: point.y}));

    const data = {
      datasets: [
        {
          label: 'Objective Progress',
          data: [
            {x: new Date(objective.start_date), y: objective.initial_value},
            {x: new Date(objective.end_date), y: objective.target_value}
          ],
          borderColor: 'rgba(128, 128, 128, 1)',
          pointRadius: 2,
          borderDash: [5, 5],
          fill: false
        },
        {
          label: 'Current Value',
          data: [
            {x: new Date(), y: objective.current_value}
          ],
          borderColor: 'rgba(255, 121, 63, 1)',
          backgroundColor: 'rgba(255, 121, 63, 1)',
          pointRadius: 5,
          fill: false
        },
        {
          label: 'Server Data',
          data: formattedLineData,
          borderColor: 'rgba(255, 121, 63, 1)', // you can change the color as per your requirement
          pointRadius: 0,
          fill: false,
        }
      ]
    };

    new Chart(ctx, {
      type: 'line',
      data: data,
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            display: false,
            type: 'time',
            time: {
              unit: 'month'
            },
            grid: {
              display: false
            }
          },
          y: {
            display: false,
            grid: {
              display: false
            }
          }
        }
      }
    });
  }
}
