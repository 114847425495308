import { Controller } from "@hotwired/stimulus";
import { Modal } from "../../vendor/bootstrap.min.js";

export default class extends Controller {
  static targets = [ "modalFrame", "modalBody" ]

  connect(){
    //console.log("modal connected")
    //var myModal = Modal.new(document.getElementById('myModal'), {})
    //myModal.show()
    var el = document.getElementById('myModal');
    this.modal = new Modal(el, {});
    this.modal.show()
  }

  close() {
    //this.element.parentElement.removeAttribute("src")
    //this.element.remove()
    this.modal.hide()
  }

  submitEnd(e) {
    if (e.detail.success) {
      this.close()
    }
  }

  closeWithKeyboard(e) {
    if (e.code == "Escape") {
      this.close()
    }
  }

  closeBackground(e) {
    if (e && this.modalFrameTarget == e.target) {
      this.close()
    }
  }

}