// app/javascript/controllers/chart_controller.js

import { Controller } from "@hotwired/stimulus";
import Chart from "chart.js/auto";
import 'chartjs-adapter-moment';
import { createConsumer } from "@rails/actioncable"

import trading from './utils.js';
import annotationPlugin from 'chartjs-plugin-annotation';
Chart.register(annotationPlugin);


export default class extends Controller {

  static values = {
    chartType: {type: String, default: 'line'},
    dataStream: Object,
    chartFunction: String,
    start: String,
    stop: String,
    scaleType: {type: String, default: 'logarithmic'},
    overlayId: {type: String, default: 'none'}
  }

  disconnect() {
    this.consumer.subscriptions.remove(this.dataStreamSubscription);
  }

  onNewData(data){
    this.fetchData(this.dataStreamValue.id);
  }

  scaleChanged(event){
    this.scaleTypeValue = event.target.value;
    this.chart.options.scales.y.type = this.scaleTypeValue;
    this.chart.update();
  }

  startChanged(event){
    this.startValue = event.target.value;
    this.fetchData();
  }

  overlayChanged(event){
    
    this.overlayIdValue = event.target.value;
    if(this.overlayId == 'none'){
      this.chart.data.datasets.pop();
      this.chart.update();
    }else{
      this.fetchData()
    }
  }

  subscribeToData(id){
    this.consumer = createConsumer();

    this.dataStreamSubscription = this.consumer.subscriptions.create(
      {
        channel: 'ForwardCable::DataStreamChannel',
        data_stream_id: id
      },
      {
        received: this.onNewData.bind(this),
      }
    )
  }

  async fetchData(){
    let mainDataSet = this.chart.data.datasets[0];
    let overlayDataSet = this.chart.data.datasets[1];

    const response = await fetch(`/data_streams/${this.dataStreamValue.id}/chart_data?function=${this.chartFunctionValue}&start=${this.startValue}&stop=${this.stopValue}`);
    const lineData = await response.json();
    const formattedLineData = lineData.map(point => ({x: point.x, y: point.y}));

    mainDataSet.data = formattedLineData;

    //color the line
    /*let values = [];
    formattedLineData.map(m => { values.push(m.y) });
    values = trading.reScaleArray(values, 0, 100)
    let colors = trading.buildSpectrumColors(values, false, 'rainbow');
    mainDataSet.borderColor = colors;
    mainDataSet.pointBackgroundColor = colors;*/

    if(this.overlayIdValue != 'none'){
      const overlayResponse = await fetch(`/data_streams/${this.overlayIdValue}/chart_data?function=${this.chartFunctionValue}&start=${this.startValue}&stop=${this.stopValue}`);
      const overlayData = await overlayResponse.json();
      const formattedOverlayData = overlayData.map(point => ({x: point.x, y: point.y}));
      overlayDataSet.data = formattedOverlayData;
      let lastValue = formattedOverlayData[formattedOverlayData.length - 1].y;
      let annotation = {type: 'line', scaleID: 'y2', value: lastValue, label: { content: "", enabled: true, position: 'end' }, borderColor: 'rgb(235, 113, 0)',  borderWidth:0.5 };
      this.chart.options.plugins.annotation.annotations["lastValue"] = annotation;

      let values = [];
      
      formattedOverlayData.map(m => { values.push(m.y) });
      
      values = trading.reScaleArray(values, 0, 100)

      // values, inverse, kind
      let colors = trading.buildSpectrumColors(values, false, 'rainbow');


      let diff = mainDataSet.data.length - colors.length;
      
      while(diff > 0){
        //colors.unshift('#4B5663')
        diff -= 1;
      }

      overlayDataSet.pointBackgroundColor = colors;
      overlayDataSet.pointBorderColor = colors;
      // show y2 axis
      this.chart.options.scales.y2.display = true;
      

      //this.chart.update();
    }else{
      // hide axis
      overlayDataSet.data = [];
    }

    // halving annotations
    const halvingDates = trading.halvingDates;
        
    // we don't want to add annotations outside of the scope of the chart
    for(let i = 0; i < halvingDates.length; i++){
        if(this.chart.data.datasets[0].data[0]){
        let minDate = this.chart.data.datasets[0].data[0].x;
        if(halvingDates[i] > minDate){
            let annotation = {type: 'line', scaleID: 'x', value: halvingDates[i], label: { content: "Halving", enabled: true, position: 'end' }, borderColor: 'rgb(235, 113, 0)',  borderWidth:0.5 };
            this.chart.options.plugins.annotation.annotations["halving" + i] = annotation;
        }
      }
    }
    this.chart.update();

    //this.chart.update();

  }
  getMainColor(){
    let color = 'rgb(222, 226, 230)';
    let colorMode = localStorage.getItem('colorMode');

    if(colorMode == 'dark'){
      //this.changeColorMode('dark');
    }else if(colorMode == 'light'){
      color = '#4B5663';
    }else{
    }
    return color;
  }
  async connect() { // use async because fetch returns a Promise

    this.mainChartColor = this.getMainColor(); // you can change the color as per your requirement

    const ctx = this.element.querySelector("canvas").getContext("2d");
    const datasets = {
      datasets: [
     
        {
          label: this.dataStreamValue.name,
          data: [],
          borderColor: this.mainChartColor, // you can change the color as per your requirement
          fill: false,
          spanGaps: true,
          yAxisID: 'y',
          pointBorderWidth: 0,
          pointRadius: 0,
          borderWidth: 0.7,
        },
        {
          label: 'Overlay',
          data: [],
          borderColor: '#4B5663', // you can change the color as per your requirement
          borderWidth: 1,
          pointRadius: 1,
          fill: false,
          spanGaps: true,
          yAxisID: 'y2'
        }
      ]
    };

    this.chart = new Chart(ctx, {
      type: this.chartTypeValue,
      data: datasets,
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        animation: {
          duration: 0
        },
        scales: {
          x: {
            display: true,
            type: 'time',
            time: {
              unit: 'month'
            },
            grid: {
              display: false
            }
          },
          y: {
            display: true,
            type: this.scaleTypeValue,
            grid: {
              display: false
            }
          },
          y2: {
            display: false,
            type: this.scaleTypeValue,
            position: 'right',
            grid: {
              display: false
            }
          }
        }
      }
    });

    this.subscribeToData(this.dataStreamValue.id)
    this.fetchData();
     
  }
}
